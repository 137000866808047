<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
        <el-table :data='custDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="ct_name" label="客户名"></el-table-column>
            <el-table-column align="center" prop="ct_contacts" label="联系人"></el-table-column>
            <el-table-column align="center" prop="ct_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="ct_addr" label="地址"></el-table-column>
            <el-table-column align="center" prop="ct_remark" label="备注"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-link type="primary" @click="EditClick(scope.row)">修改</el-link>
                  <el-link type="danger" @click="DelClick(scope.row)">删除</el-link>
                  <el-link type="primary" @click="UserOpt(scope.row)">操作员</el-link>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="customerForm" :rules="rules" ref="customerForm" label-width="100px" size='medium'>
                <el-form-item label="客户名" prop="ct_name">
                    <el-input v-model="customerForm.ct_name"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                    <el-input v-model="customerForm.ct_contacts"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <el-input v-model="customerForm.ct_phone"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="customerForm.ct_addr"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="customerForm.ct_remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog v-if="userDialogVisible" :title="title" :visible.sync="userDialogVisible" width='50%' :append-to-body='true'>
          <CustomerUser :userType="7" :beglongId="coustomerId"/>
          <span slot="footer" class="dialog-footer">
              <el-button @click="userDialogVisible = false">关 闭</el-button>
          </span>
      </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import CustomerUser from '../../components/servicer/SerUser.vue'


export default {
  data: function () {
    return {
      custDataList: [],
      title: '',
      dialogVisible: false,
      customerForm: {
        ct_id: 0,
        ct_name: '',
        ct_contacts: '',
        ct_phone: '',
        ct_addr: '',
        ct_remark: ''
      },

      userDialogVisible:false,
      coustomerId:0,

      rules: {
        ct_name: [{ required: true, message: '请输入客户名称', trigger: 'blur' }]
      }
    }
  },
  components:{
    CustomerUser
  },
  created () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Get('customer/getcustomerdatalist').then(res => {
        if (res.rpStatus === 10000) {
          this.custDataList = res.list
        }
      })
    },
    AddClick () {
      this.title = '新增'
      this.dialogVisible = true
      this.customerForm.ct_id = 0
      this.customerForm.ct_name = ''
      this.customerForm.ct_contacts = ''
      this.customerForm.ct_phone = ''
      this.customerForm.ct_addr = ''
      this.customerForm.ct_remark = ''
    },
    EditClick (row) {
      this.title = '修改'
      this.dialogVisible = true
      this.customerForm = JSON.parse(JSON.stringify(row))
    },
    DelClick (row) {
      this.$confirm('确定删除客户“' + row.ct_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Get('customer/del?id=' + row.ct_id).then(res => {
          if (res.rpStatus === 10000) { this.GetDataList() } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
        })
      }).catch(e => e)
    },
    submit () {
      this.$refs.customerForm.validate((valid) => {
        if (valid) {
          util.Post('customer/edit', this.customerForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpStatus) }
          })
        }
      })
    },
    UserOpt(row){
      console.log(row)
      this.coustomerId=row.ct_id
      this.userDialogVisible=true
      this.title="操作员"
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
